// store/index.ts

import { createStore } from 'vuex';
import CryptoJS from 'crypto-js';

// 암호화 키 (반드시 안전한 곳에 보관되어야 함)
const ENCRYPTION_KEY = 'stepUASDFQWERZXCV1234';

const encrypt = (data:any) => {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
  return encryptedData;
};

const decrypt = (encryptedData:any) => {
  const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};

const store = createStore({
  state: {
    MainLayoutScroll: 0,

    id: null,
    role: 0,
    roleName: null,
    code: null,
    name: null,
    drawer: true,
    selectedMember: null, // 추가된 부분: 선택된 멤버 정보
    crawlResultList: [], // 추가된 부분: 모든 정보 배열
    randomCode: null,
    boardCode: '',
    blogCode: '',

    MngIntoSs: [] as any, // 관리자가 고객관리에서 기관관리창으로 넘어갈 때 기관정보 담음.

    SsInfoBox: [] as any, // 기관권한이 기관정보탭에 갈 시, 기관정보 담는 그릇
    wageDirectPushBox: [] as any, // 상세진단에서 바로 임금대장 insert할 테이터를 담을 그릇
    wageDirectPushData: [] as any, // 상세진단에서 바로 임금대장 insert할 데이터
    mngOneClient: [] as any, // Mng 상세진단요청진행기관 선택
    status2Form: [] as any, // Mng 상세진단요청진행기관 선택
    mng2Form: [] as any, // Mng 상세진단요청진행기관 선택
    MngTitle : [] as any,
    SsTitle: [] as any,
    SsECDCompo: [] as any,
    SsECDEmpInfo: [] as any,

    SsYm: '',
  },
  mutations: {
    setId(state, id) {
      state.id = id;
      sessionStorage.setItem('vuex_state', encrypt(state));
    },
    setRole(state, role) {
      state.role = role;
      sessionStorage.setItem('vuex_state', encrypt(state));
    },
    setRoleName(state, roleName) {
      state.roleName = roleName;
      sessionStorage.setItem('vuex_state', encrypt(state));
    },
    setCode(state, code) {
      state.code = code;
      sessionStorage.setItem('vuex_state', encrypt(state));
    },
    setName(state, name) {
      state.name = name;
      sessionStorage.setItem('vuex_state', encrypt(state));
    },
    setDrawer(state, value) {
      state.drawer = value;
    },
  
    clearId(state) {
      state.id = null;
    },
    clearRole(state) {
      state.role = 0;
    },
    clearRoleName(state) {
      state.roleName = null;
    },
    clearCode(state) {
      state.code = null;
    },
    setSelectedMember(state, member) {
      state.selectedMember = member;
      sessionStorage.setItem('selected_member', encrypt(state));
    },
    setBoardCode(state, boardCode) {
      state.boardCode = boardCode;
      sessionStorage.setItem('getBoardCode', encrypt(state));
    },
    setBlogCode(state, blogCode) {
      state.blogCode = blogCode;
      sessionStorage.setItem('getBlogCode', encrypt(state));
    },
    setMainLayoutScroll(state, MainLayoutScroll) {
      state.MainLayoutScroll = MainLayoutScroll;
      sessionStorage.setItem('getMainLayoutScroll', encrypt(state));
    },
    setSsTitle(state, SsTitle) {
      state.SsTitle = SsTitle;
      sessionStorage.setItem('getSsTitle', encrypt(state));
    },
    setMngTitle(state, MngTitle) {
      state.MngTitle = MngTitle;
      sessionStorage.setItem('getMngTitle', encrypt(state));
    },
    setSsECDCompo(state, SsECDCompo) {
      state.SsECDCompo = SsECDCompo;
      sessionStorage.setItem('getSsECDCompo', encrypt(state));
    },
    setSsECDEmpInfo(state, SsECDEmpInfo) {
      state.SsECDEmpInfo = SsECDEmpInfo;
      sessionStorage.setItem('getSsECDEmpInfo', encrypt(state));
    },
    setSsYm(state, SsYm) {
      state.SsYm = SsYm;
      sessionStorage.setItem('getSsYm', encrypt(state));
    },
    setCrawlResultList(state, crawlResultList) {
      state.crawlResultList = crawlResultList;
      sessionStorage.setItem('crawl_Result_List', encrypt(state));
    },
    setRandomCode(state, newValue) {
      state.randomCode = newValue;
    },


    setMngOneClient(state, mngOneClient) {
      state.mngOneClient = mngOneClient;
      sessionStorage.setItem('mngOneClient', encrypt(state));
    },
    setStatus2Form(state, status2Form) {
      state.status2Form = status2Form;
      sessionStorage.setItem('status2Form', encrypt(state));
    },
    setMng2Form(state, mng2Form) {
      state.mng2Form = mng2Form;
      sessionStorage.setItem('mng2Form', encrypt(state));
    },
    setWageDirectPushBox(state, wageDirectPushBox) {
      state.wageDirectPushBox = wageDirectPushBox;
      sessionStorage.setItem('wageDirectPushBox', encrypt(state));
    },

    setSsInfoBox(state, SsInfoBox) {
      state.SsInfoBox = SsInfoBox;
      sessionStorage.setItem('SsInfoBox', encrypt(state));
    },
    setMngIntoSs(state, MngIntoSs) {
      state.MngIntoSs = MngIntoSs;
      sessionStorage.setItem('MngIntoSs', encrypt(state));
    },
    
    setWageDirectPushData(state, wageDirectPushData) {
      state.wageDirectPushData = wageDirectPushData;
      sessionStorage.setItem('wageDirectPushData', encrypt(state));
    },
  },
  actions: {
    login({ commit }, { id, role ,roleName, name, code}) {
      commit('setId', id);
      commit('setRole', role);
      commit('setRoleName', roleName);
      commit('setName', name);
      commit('setCode', code);
    },
    toggleDrawer({ commit, state }) {
      commit('setDrawer', !state.drawer);
    },
    logout({ commit }) {
      commit('clearRole');
    },
    SetMemberInfoDetail({ commit }, member) {
      commit('setSelectedMember', member);
    },
    updateRandomCode({ commit }, newValue) {
      commit('setRandomCode', newValue);
    }
  },
  getters: {
    isLoggedIn: (state) => !!state.role,
    id: (state) => state.id,
    role: (state) => state.role,
    roleName: (state) => state.roleName,
    code: (state) => state.code,
    getDrawerState: (state) => state.drawer,
    getMemberInfoDetail: (state) => state.selectedMember,
  },
});

// 페이지 로딩 시 sessionStorage에서 저장된 암호화된 상태를 복호화하여 불러옵니다.
const savedState = sessionStorage.getItem('vuex_state');
if (savedState) {
  store.replaceState(decrypt(savedState));
}
const savedSession = sessionStorage.getItem('selected_member');
if(savedSession){
  store.replaceState(decrypt(savedSession));
}
const savedBoardCode = sessionStorage.getItem('getBoardCode');
if (savedBoardCode) {
  store.commit('setBoardCode', decrypt(savedBoardCode).boardCode);
}
const savedBlogCode = sessionStorage.getItem('getBlogCode');
if (savedBlogCode) {
  store.commit('setBlogCode', decrypt(savedBlogCode).blogCode);
}
const savedMainLayoutScroll = sessionStorage.getItem('getMainLayoutScroll');
if (savedMainLayoutScroll) {
  store.commit('setMainLayoutScroll', decrypt(savedMainLayoutScroll).MainLayoutScroll);
}

const savedSsTitle = sessionStorage.getItem('getSsTitle');
if (savedSsTitle) {
  store.commit('setSsTitle', decrypt(savedSsTitle).SsTitle);
}
const savedMngTitle = sessionStorage.getItem('getMngTitle');
if (savedMngTitle) {
  store.commit('setMngTitle', decrypt(savedMngTitle).MngTitle);
}
const savedSsECDCompo = sessionStorage.getItem('getSsECDCompo');
if (savedSsECDCompo) {
  store.commit('setSsECDCompo', decrypt(savedSsECDCompo).SsECDCompo);
}
const savedSsECDEmpInfo = sessionStorage.getItem('getSsECDEmpInfo');
if (savedSsECDEmpInfo) {
  store.commit('setSsECDEmpInfo', decrypt(savedSsECDEmpInfo).SsECDEmpInfo);
}
const savedSsYm = sessionStorage.getItem('getSsYm');
if (savedSsYm) {
  store.commit('setSsYm', decrypt(savedSsYm).SsYm);
}

const savedCrawlResultList = sessionStorage.getItem('crawl_Result_List');
if (savedCrawlResultList) {
  store.commit('setCrawlResultList', decrypt(savedCrawlResultList).crawlResultList);
}


const savedMngOneClient = sessionStorage.getItem('mngOneClient');
if (savedMngOneClient) {
  store.commit('setMngOneClient', decrypt(savedMngOneClient).mngOneClient);
}
const savedStatus2Form = sessionStorage.getItem('status2Form');
if (savedStatus2Form) {
  store.commit('setStatus2Form', decrypt(savedStatus2Form).status2Form);
}
const savedMng2Form = sessionStorage.getItem('mng2Form');
if (savedMng2Form) {
  store.commit('setMng2Form', decrypt(savedMng2Form).mng2Form);
}
const savedWageDirectPushBox = sessionStorage.getItem('wageDirectPushBox');
if (savedWageDirectPushBox) {
  store.commit('setWageDirectPushBox', decrypt(savedWageDirectPushBox).wageDirectPushBox);
}
const savedSsInfoBox = sessionStorage.getItem('SsInfoBox');
if (savedSsInfoBox) {
  store.commit('setSsInfoBox', decrypt(savedSsInfoBox).SsInfoBox);
}
const savedMngIntoSs = sessionStorage.getItem('MngIntoSs');
if (savedMngIntoSs) {
  store.commit('setMngIntoSs', decrypt(savedMngIntoSs).MngIntoSs);
}
const savedWageDirectPushData = sessionStorage.getItem('wageDirectPushData');
if (savedWageDirectPushData) {
  store.commit('setWageDirectPushData', decrypt(savedWageDirectPushData).wageDirectPushData);
}
export default store;
