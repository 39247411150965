<template>
  <div class="rightSs">
      <v-btn class="rightSsBtn align-self-center me-4"
        variant="text"
        v-for="section in sections"
        :key="section.title"
          @click="loadComponent(section.link); setActiveItem(section)"
          :class="{ 'v-btn--active': isActiveLink(section.link) }"
          :prepend-icon="section.icon"
        >
          {{ section.title }}
      </v-btn>
    </div>
  <v-card class="clientRightCardHeight">
        <v-sheet rounded="lg">
        <component :is="dynamicComponent"></component>
      </v-sheet>
      </v-card>
</template>
<script setup lang="ts">
import store from '@/store';
import { computed, onMounted, shallowRef, watch } from 'vue';
import {
  mdiFileDocumentEdit,
  mdiAccountTieOutline,
  mdiOfficeBuildingCog,
} from '@mdi/js'
const dynamicComponent = shallowRef('SsService') as any;
const activeItem = shallowRef('SsService') as any;
const sections = [
  { title: '서비스관리', link: 'SsService', icon: mdiFileDocumentEdit },
  { title: '담당자설정', link: 'SsDamdang', icon: mdiAccountTieOutline },
  { title: '설정', link: 'SsCog', icon: mdiOfficeBuildingCog },
];
const loadComponent = async (componentName: string) => {
  // 비동기적으로 컴포넌트를 불러옴
  const { default: Component } = await import(`@/components/sisul_comp/${componentName}.vue`);
  // 불러온 컴포넌트를 동적으로 설정
  dynamicComponent.value = Component;
};
const setActiveItem = (item: any) => {
  store.commit('setMngTitle', item);
  activeItem.value = item.link;
};
const isActiveLink = (link: string) => {
  return activeItem.value === link;
};
const changeMngTitle = computed(() => {
  const MngTitleComputed = store.state.MngTitle
  return MngTitleComputed;
});
watch(changeMngTitle, async (newValue:any) => {
  if (newValue) {
    const matchingSection = sections.find(section => section.link === newValue.link);
    if(matchingSection){
      activeItem.value = newValue.link;
       loadComponent(newValue.link);
    }else{
      return;
    }
  }
});
onMounted(() => {
  loadComponent(store.state.MngTitle.link);
  activeItem.value = store.state.MngTitle.link;
});
</script>