<template>
<img
    @click="dialog = true" 
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :style="{
      position: 'fixed',
      right: '1%',
      top: '40%',
      minWidth: '50px',
      minHeight: '50px',
      maxWidth: '3% !important',
      maxHeight: '6% !important',
      backgroundColor: 'aquamarine',
      borderRadius: '100%',
      cursor: 'pointer',
      transform: hover ? 'scale(1.1)' : 'scale(1)',
      transition: 'transform 0.3s ease'
    }"
    cover 
    src="@/assets/pictures/main/상담.png" 
    alt=""
  >
    <v-dialog v-model="dialog" style="width: 600px;" class="fontNotoSans400" persistent>
      <v-card>
        <v-table style="margin: 3px 0 10px 0;">
          <tbody>
            <tr>
              <td>
                <h2 class="tac">1:1 바로상담</h2>
              </td>
            </tr>
            <tr>
              <td style="display: flex; align-items: center;">        
                <v-checkbox color="success" width="34" value='임금' v-model="form.serviceWage" hide-details style="display: inline-block;"></v-checkbox>
                <span>임금관리서비스</span>
                <v-checkbox color="success" width="34" value='회계' v-model="form.serviceFna" hide-details style="display: inline-block;"></v-checkbox>
                <span>재무회계서비스</span>
              </td>
            </tr>
            <tr>
              <td>
                <div class="inquiryForm">
                  <v-text-field variant="outlined" v-model="form.compName" maxlength="30" hide-details label="기관 명" type="text" color="indigo" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="inquiryForm">
                  <v-select variant="outlined" hide-details label="사업구분" :items="compGubuns"
                  v-model="form.compGubun" color="indigo" class="comeWaySelect"></v-select>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="inquiryForm">
                  <v-text-field variant="outlined" v-model="form.phone" maxlength="13" hide-details label="휴대폰 번호" type="text" color="indigo" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="inquiryForm">
                  <v-text-field variant="outlined" v-model="form.email" maxlength="50" hide-details label="이메일" type="text" color="indigo" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="inquiryForm">
                <v-textarea v-model="form.memo" rows="2" variant="outlined" label="문의사항을 간략히 작성해주세요."  maxlength="255" hide-details></v-textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex" style="align-items: center;">
                  <v-checkbox v-model="form.policy" color="indigo" :value=true hide-details>
                  </v-checkbox>
                  <policyMent />
                </div>

              </td>
            </tr>
            <tr>
              <td v-if="loading" class="tac">
                <div class="spinner tac"></div>
              </td>
              <td v-else >
                <v-btn @click="registInquiry" class="clientInfoBtn">작성완료</v-btn>
                <v-btn @click="dialog = false" class="cancelRegistBtn">취소</v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, reactive } from 'vue';
import policyMent from './policyMent.vue';
import axios from 'axios';
const compGubuns = [
  '요양시설',
  '주간보호센터'
];
const form = reactive({
  serviceWage : '',
  serviceFna : '',
  compName : '',
  compGubun : null,
  phone : '',
  email : '',
  memo : '',
  status : 1,
  policy : false
})
const dialog = ref(false);
const hover = ref(false);

const loading = ref(false);


const registInquiry = async () => {
  console.log(form)
  if((form.serviceWage === '' || !form.serviceWage) && (form.serviceFna === '' || !form.serviceFna)){
    alert('문의하실 서비스를 선택해주세요.');
    return;
  }else if(form.compName === '' || !form.compName){
    alert('기관명을 입력해주세요.');
    return;
  }else if(form.compGubun === '' || !form.compGubun){
    alert('사업구분을 선택해주세요.');
    return;
  }else if(form.phone === '' || !form.compGubun){
    alert('휴대폰 번호를 입력해주세요.');
    return;
  }else if(form.email === '' || !form.compGubun){
    alert('이메일을 입력해주세요.');
    return;
  }else if(form.memo === '' || !form.compGubun){
    alert('문의사항을 작성해주세요.');
    return;
  }else if(form.policy === false){
    alert('개인정보처리방침에 동의해주세요.');
    return;
  }
  try {
    const isConfirmed = window.confirm(`입력하신 정보가 유효하지 않을 경우 상담문의가 자동 취소됩니다.\n상담문의를 신청하시겠습니까?`);
    if (isConfirmed) {
      loading.value = true;
    const response = await axios.post('/api/registInquiry', { form: form });
    const result = response.data;
    if (result.success === true) {
      alert(`상담신청이 완료되었습니다.\n담당자를 배정하여 연락드리도록 하겠습니다.`);
      dialog.value = false;
    } else {
      alert('상담문의 신청이 불가합니다.');
    }
  }

  } catch (error) {
    alert('상담문의 신청을 처리할 수 없습니다.');
    console.error(error);
  } finally {
    loading.value = false;
  }
  
};

</script>

<style scoped>
.spinner {
  width: 30px !important;
  margin: 0 0 18px 8px;
  display: flex;
  height: 30px !important;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>