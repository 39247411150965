<template>
  <v-card class="dashBoardCard">
    <v-card-title>
      상담문의 신청목록
    </v-card-title>
      <v-data-table fixed-header density="compact" scrollable :headers="headersArr" :items="inquiryArr"
      item-value="name" class="fontNotoSans400 SsEmpWorkPayTypeTb dashboardComponentTable"
       :items-per-page="50" :items-per-page-options="[
        { value: 50, title: '50' },
        { value: 100, title: '100' },
        { value: 200, title: '200' }
        // { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
      ]" v-if="inquiryArr.length !== 0">
      <template v-slot:item="{ item, index }">
        <tr>
          <td class="tal">{{ index + 1 }}. {{ item.hi_comp_name || '' }}</td>
          <td class="tac">
            {{ item.hi_service_wage }} {{ item.hi_service_fna }}
          </td>

          <td class="tac">
            {{ item.hi_mng_id }}
          </td>

          <td class="tac">
            {{ item.wdate }}
          </td>
          <td class="tac">
              <span v-if="item.hi_status === 0">
                {{ item.status_name }}
              </span>
              <span v-else-if="item.hi_status === 1">
                {{ item.status_name }}
              </span>
              <span v-else-if="item.hi_status === 2">
                {{ item.status_name }}
              </span>
              <span v-else-if="item.hi_status === 3">
                {{ item.status_name }}
              </span>
          </td>
        </tr>
      </template>

    </v-data-table>
  </v-card>
</template>
<script setup lang="ts">
import axios from 'axios';
import {  onMounted, ref } from 'vue';

const inquiryArr = ref([] as any[]);
const headersArr = ref([
  { title: '기관명', key: 'hi_comp_name', align: 'start', sortable: true },
  { title: '희망서비스', key: 'hi_service_wage', align: 'center', sortable: true },
  { title: '담당자', key: 'hi_mng_id', align: 'center', sortable: true },
  { title: '신청일', key: 'hi_wdate', align: 'center', sortable: true },
  { title: '상태', key: 'hi_status', align: 'center', sortable: true },

] as any[]);

const getInquiry = async () => {
  try {
    const result = await axios.get(`/api/getInquiry/`);
    const response = result.data;
    if (response) {
      inquiryArr.value = response
      return response;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};
onMounted(async () => {
  await getInquiry();
})
</script>
