
export const idRule = [
  (v: string) => !!v || '아이디를 입력해주세요.',
  (v: string) => v.length <= 30 || 'ID는 30자 이하여야 합니다.',
  (v: string) => v.length >= 6 || 'ID는 6자 이상이여야 합니다.',

];


export const pwRule = [
  (v: string) => !!v || '비밀번호를 입력해주세요.',
  (v: string) => v.length >= 8 || '비밀번호는 최소 8자 이상이어야 합니다.',
  (v: string) => v.length <= 30 || '비밀번호는 30자 이하여야 합니다.',
];


export const emailRule = [
  (v: string) => !!v || '이메일을 입력해주세요.',
  (v: string) => /.+@.+\..+/.test(v) || '유효한 이메일 형식이어야 합니다.'
];
export const compNameRule = [
  (v: string) => !!v || '기관명을 입력해주세요.',
  (v: string) => v.length <= 30 || '기관명은 30자 이하여야 합니다.',
];

export const compGubunRule = [
  (v: string) => !!v || '사업 구분을 입력해주세요.',
  (v: string) => v.length <= 10 || '사업 구분은 10자 이하여야 합니다.',
];


export const nameRule = [
  (v: string) => !!v || '이름을 입력해주세요.',
  (v: string) => v.length <= 10 || '이름은 10자 이하여야 합니다.',
  (v: string) =>/^[가-힣]+$/.test(v) || '이름은 한글로만 입력해야 합니다.',
];


export const addrRule = [
  (v: string) => !!v || '주소를 입력해주세요.',
  (v: string) => v.length <= 100 || '주소는 100자 이하여야 합니다.',
];

export const bossNameRule = [
  (v: string) => !!v || '대표자명을 입력해주세요.',
  (v: string) => v.length <= 10 || '대표자명은 10자 이하여야 합니다.',
  (v: string) =>/^[가-힣]+$/.test(v) || '대표자명은 한글로만 입력해야 합니다.',
];

export const mngNameRule = [
  (v: string) => v.length <= 10 || '담당자 성명은 10자 이하여야 합니다.',
  (v: string) =>/^[가-힣]+$/.test(v) || '담당자 성명은 한글로만 입력해야 합니다.',
];


export const phoneRule = [ 
  (v: string) => !!v || '담당자 연락처를 입력해주세요.',
  (v: string) => v.length <= 11 || '번호는 11자 이하여야 합니다.',
  (v: string) => /^\d+$/.test(v) || '숫자만 입력해주세요.',
];

export const phoneNumberRule = [ 
  (v: string) => !!v || '휴대폰 번호를 입력해주세요.',
  (v: string) => v.length <= 11 || '번호는 11자 이하여야 합니다.',
  (v: string) => /^\d+$/.test(v) || '숫자만 입력해주세요.',
];

export const jungwonRule = [ 
  (v: string) => !!v || '수급자 정원을 입력해주세요.',
  (v: string) => /^\d+$/.test(v) || '숫자만 입력해주세요.',
];

export const localNumberRule = [ 
  (v: string) => !!v || '전화번호를 입력해주세요.',
  (v: string) => v.length <= 11 || '번호는 11자 이하여야 합니다.',
  (v: string) => /^\d+$/.test(v) || '숫자만 입력해주세요.',
];
export const compNumberRule = [ 
  (v: string) => !!v || '장기요양기관번호를 입력해주세요.',
  (v: string) => v.length <= 11 || '장기요양기관번호는 11자 이하여야 합니다.',
  (v: string) => /^\d+$/.test(v) || '숫자만 입력해주세요.',
];


export const authNumRule = [ 
  (v: string) => !!v || '인증번호를 입력해주세요.',
  (v: string) => v.length <= 6 || '인증번호는 6자 입니다.',
  (v: string) => /^\d+$/.test(v) || '숫자만 입력해주세요.',

];

export const numRule = [
  (v: string) => v === '' || /^[\d,]+$/.test(v) || '숫자만 입력해주세요. (천 단위 구분 쉼표 허용)',
];


export const workerRule = [
  (v: string) => !!v || '직종을 입력해주세요.',
  (v: string) => v.length <= 20 || '직종은 20자 이하여야 합니다.',
  (v: string) => /\D/.test(v) || '숫자만 입력되어있으면 안됩니다.'
];

export const bizNumberRule = [ 
  (v: string) => !!v || '사업자번호를 입력해주세요.',
  (v: string) => v.length <= 13 || '사업자번호는 10자 이하여야 합니다.',
  (v: string) => /^\d+$/.test(v) || '숫자만 입력해주세요.',
];
export const bankRule = [ 
  (v: string) => !!v || '은행을 선택해주세요.',
];

export const bankNumRule = [ 
  (v: string) => !!v || '계좌번호를 입력해주세요.',
  (v: string) => v.length <= 20 || '계좌번호는 30자 이하여야 합니다.',
  (v: string) => /^\d+$/.test(v) || '숫자만 입력해주세요.',
];

export const bankOwnerRule = [ 
  (v: string) => !!v || '예금주를 입력해주세요.',
  (v: string) => v.length <= 10 || '예금주는 10자 이하여야 합니다.',
  (v: string) =>/^[가-힣]+$/.test(v) || '예금주는 한글로만 입력해야 합니다.',
];

export const empBirthNumFrontRule = [ 
  (v: string) => !!v || '주민번호 앞자리를 입력해주세요.',
  (v: string) => v.length === 6 || '주민번호 앞자리 6자를 입력해주세요.',
  (v: string) => /^\d+$/.test(v) || '숫자만 입력해주세요.',
];
export const empBirthNumBackRule = [ 
  (v: string) => !!v || '주민번호 뒷자리를 입력해주세요.',
  (v: string) => v.length === 7 || '주민번호 뒷자리 7자를 입력해주세요.',
  (v: string) => /^\d+$/.test(v) || '숫자만 입력해주세요.',
];
export const empJobRule = [
  (v: string) => !!v || '직위을 선택해주세요.',
];
export const empInDateRule = [
  (v: string) => !!v || '입사일을 입력해주세요.',
];


