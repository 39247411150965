<template>
  <v-card class="dashBoardCard">
    <v-card-title>
      상세진단 현황
    </v-card-title>
      <v-data-table fixed-header density="compact" scrollable :headers="headersArr" :items="allClientList" 
      item-value="name" class="fontNotoSans400 SsEmpWorkPayTypeTb dashboardComponentTable" 
      :items-per-page="-1" :items-per-page-options="[
        // { value: 50, title: '50' },
        // { value: 100, title: '100' },
        // { value: 200, title: '200' },
        { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
      ]" v-if="allClientList.length !== 0">
      <template v-slot:item="{ item, index }">
        <tr>
          <td class="tal">{{ index + 1 }}. {{ item.comp_name || '' }}</td>
          <td class="tal">{{ item.sido_name || '' }}/{{ item.sigungu_name || '' }}</td>
          <td class="tal">{{ item.fwa_upload_date || '' }}
            <div v-if="item.fwa_status === 0 || item.fwa_status === 1">
              {{ item.Elapsed_time }}시간 경과
            </div></td>
          <td class="tal">
            <span>{{ item.fwa_manager }}</span>
          </td>
          <td class="tal">
            <span v-if="item.fwa_status === 0">
              {{ item.fwa_status_case || '' }}
            </span>
            <span v-else-if="item.fwa_status === 1">
              {{ item.fwa_status_case || '' }}
            </span>
            <span v-else-if="item.fwa_status === 2">
              {{ item.fwa_status_case || '' }}
            </span>
            <span v-else-if="item.fwa_status === 3">
              {{ item.fwa_status_case || '' }}
            </span>
            <span v-else-if="item.fwa_status === 4">
              {{ item.fwa_status_case || '' }}
            </span>
            <span v-else-if="item.fwa_status === 5">
              {{ item.fwa_status_case || '' }}
            </span>
            <span v-else-if="item.fwa_status === 6">
              {{ item.fwa_status_case || '' }}
            </span>

            <br>
            <div v-if="item.fwa_status === 5 || item.fwa_status === 6">
              {{ item.fwa_req_wage || '' }}
              {{ item.fwa_req_fna || '' }}
              {{ item.fwa_req_meal || '' }}
            </div>
          </td>
        </tr>
      </template>

    </v-data-table>
  </v-card>
</template>
<script setup lang="ts">
import axios from 'axios';
import { onMounted, ref } from 'vue';
const allClientList = ref([] as any[])

const headersArr = ref([
  { title: '기관명', key: 'comp_name', align: 'start', sortable: true },
  { title: '지역', key: 'sido_name', align: 'start', sortable: true },
  { title: '요청일자', key: 'fwa_upload_date', align: 'start', sortable: true },
  { title: '담당자', key: 'fwa_manager', align: 'start', sortable: true },
  { title: '진단상태', key: 'fwa_status', align: 'start', sortable: true },
] as any[]);

const readAllClient = async () => {
  try {
    const response = await axios.get(`/api/readAllClient`);
    const responseData = response.data;
    if (responseData) {
      allClientList.value = responseData;
      
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};


onMounted(async () => {
  await readAllClient();
})
</script>