<template>
  <v-card class="login_card" elevation="3"> <!--elevation : 그림자-->
      <div class="pa-10">
        <h1 style="text-align: center" class="mb-10">로그인</h1>
        <v-form action="submit" method="post" @submit.prevent="login">
          <v-text-field :prepend-inner-icon="mdiAccount" variant="outlined" label="ID" type="text" id="loginId" v-model="form.loginId" autocomplete="username" />
          <v-text-field :prepend-inner-icon="mdiLock" variant="outlined" label="PW" hint="Enter your password to access this website" type="password" id="loginPw" v-model="form.loginPw" autocomplete="current-password" />
          <v-btn
            type="submit"
            color="blue lighten-1 text-capitalize"
            depressed
            large
            block
            dark
            class="mb-3"
          >
            로그인
          </v-btn>
          <v-btn
            color="blue lighten-1 text-capitalize"
            depressed
            large
            block
            dark
            @click="goToRegist"
          >
            회원가입
          </v-btn>
        </v-form>
      </div>
    </v-card>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import axios from 'axios';
import store from '../.././store';
import { mdiAccount,mdiLock } from '@mdi/js'
import router from '@/router';
import { format } from 'date-fns';

const goToRegist = () => {
  router.push('/RegistPage');
}

const account = ref({
id: null,
name: '',
role: 0,
roleName: '',
code: '',
});

const form = ref({
loginId: '',
loginPw: ''
});
const login = () => {
const args = {
loginId: form.value.loginId,
loginPw: form.value.loginPw
};

axios.post("/api/account", args).then(async (res:any) => {

  account.value = res.data.member;
  if(res.data.member.role === 0){
    alert('회원 인증이 되지 않았습니다.');
    router.push('/');
    return;
  }
  await store.dispatch('login', { id: res.data.member.id, role: res.data.member.role ,roleName: res.data.member.role_name, name: res.data.member.name, code: res.data.member.code });
  
  store.commit('setSsYm', format(new Date(), 'yyyy-MM'))

  window.location.reload();

  // router.push({
  //   path: "/",
  // });
})
.catch(() => {
  alert("로그인에 실패했습니다. 계정 정보를 확인해주세요.");
});

};


</script>



