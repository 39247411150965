<template>
  <v-card class="normalMngCard" style="width: 100%;">
  <div v-if="loading" class="spinner tac"></div>
  <v-data-table v-else 
  fixed-header density="compact" 
  scrollable :headers="headersArr" 
  :items="holidayList" item-value="name"
    class="fontNotoSans400" style="
        height: calc(100vh - 217px); 
        overflow-y: hidden; 
        position: relative;
          " :items-per-page="50" :items-per-page-options="[
    { value: 50, title: '50' },
    { value: 100, title: '100' },
    { value: 200, title: '200' }
    // { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
  ]">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title class="fontNotoSans700">
          유급휴일설정
        </v-toolbar-title>
       

        <v-divider class="mx-4" inset vertical></v-divider>
        
        <v-btn @click="dialogOpen(currentYear, currentMonth)" class="btnColorBlue">휴일등록</v-btn>
        <v-divider class="mx-4" inset vertical></v-divider>
      </v-toolbar>
    </template>
    <template v-slot:item="{ item }">
      <tr class="SsTbTr" style="cursor: pointer;" @click="dialogOpen(item.hh_year, item.hh_month)" >
        <td class="tac">{{ item.hh_year }}</td>
        <td class="tac">{{ item.hh_month }}</td>
        <td class="tac">{{ item.hh_days }}</td>
        <td class="tac">{{ item.wdate }}</td>
        <td class="tac">{{ item.rdate }}</td>
      </tr>
    </template>
  </v-data-table>
</v-card>

<v-dialog v-model="dialog" persistent width="450px" class="fontNotoSans400">
    <v-card>
      <v-card-text>
        <v-toolbar flat>
          <v-toolbar-title class="fontNotoSans700">
            휴일등록
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <div class="tac mr-4">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" class="btnColorWhite" style="width: auto; font-size: 19px; cursor: pointer;">{{ currentMonthDisplay }}
                </v-btn>
              </template>
              <v-card style="width: 300px;">
                <v-card-title style="display: flex; align-items: center; justify-content: center;">
                  <v-icon color="#002e00" @click="previousYear" size="48" class="pt-1">{{ mdiChevronLeft }}</v-icon>
                  <h2 class="text-center">{{ currentYear }}</h2>
                  <v-icon color="#002e00" @click="nextYear" size="48" class="pt-1">{{ mdiChevronRight }}</v-icon>
                </v-card-title>
                <v-card-text>
                  <v-row class="pa-4" justify="center">
                    <v-col v-for="month in months" :key="month.value" cols="3" class="d-flex justify-center">
                      <v-btn class="monthLabelBtn" :class="{ 'btnBg': month.value === currentMonth, 'ma-1': true }"
                        @click="selectMonth(month)">
                        {{ month.label }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>

        </v-toolbar>
      </v-card-text>
      <v-card-text>

        <v-row class="pa-4" >
          <v-col v-for="day in daysInMonth" :key="day" cols="2" class="pr-0 pl-0 d-flex justify-center">
            <label style="align-items: center;">
              <span style="display: flex; justify-content: center; align-items: center;">{{ day }}</span>
              <input
                type="checkbox"
                :value="day"
                v-model="selectedDays"
                style="cursor: pointer; width: 22px; height: 22px;"
              />
            </label>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <div v-if="loading" class="spinner tac"></div>
        <v-btn style="width: 100%; font-size: 16px;" class="btnColorBlue mb-1" @click="setHdayModal()">휴일 등록하기</v-btn>
        <v-btn style="width: 100%; font-size: 16px;" class="btnColorDark" @click="notselectDay()">닫기</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>

</template>
<script setup lang="ts">
import axios from 'axios';
import { onMounted, ref, computed } from 'vue';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { format,  addYears,  setMonth } from 'date-fns';
const dialog = ref(false);
const selectedDays = ref([] as any);
const loading = ref(false);
const holidayList = ref([] as any[])
const currentDate = ref(new Date());
const date = ref(format(currentDate.value, 'yyyy-MM'));
const currentYear = computed(() => (format(currentDate.value, 'yyyy')));
const currentMonth = computed(() => format(currentDate.value, 'MM'));
let currentMonthDisplay = computed(() => format(currentDate.value, 'yyyy-MM'));

const headersArr = ref([
  { title: '연도', key: 'index', align: 'center', sortable: true },
  { title: '월', key: 'emp_num', align: 'center', sortable: true },
  { title: '일자', key: 'emp_name', align: 'center', sortable: true },
  { title: '등록일자', key: 'emp_job', align: 'center', sortable: true },
  { title: '수정일자', key: 'emp_job', align: 'center', sortable: true },
] as any[]);



const dialogOpen = async (year: any, month: any) => {
  dialog.value = true;
  selectedDays.value = [];
  currentDate.value = new Date(year, month - 1, 1);  // month는 0부터 시작하므로 -1
  await getHdayModal(year, month);
}

const notselectDay = () => {
  dialog.value = false;
};
const months = [
  { label: '1월', value: '01' },
  { label: '2월', value: '02' },
  { label: '3월', value: '03' },
  { label: '4월', value: '04' },
  { label: '5월', value: '05' },
  { label: '6월', value: '06' },
  { label: '7월', value: '07' },
  { label: '8월', value: '08' },
  { label: '9월', value: '09' },
  { label: '10월', value: '10' },
  { label: '11월', value: '11' },
  { label: '12월', value: '12' },
];

// Year navigation functions
const previousYear = () => {
  currentDate.value = addYears(currentDate.value, -1);
  updateStore();
};

const nextYear = () => {
  currentDate.value = addYears(currentDate.value, 1);
  updateStore();
};

// Month selection function
const selectMonth = (month: { value: string; }) => {
  const monthIndex = parseInt(month.value, 10) - 1;
  currentDate.value = setMonth(currentDate.value, monthIndex);
  updateStore();
};

// Store update function
const updateStore = async () => {
  date.value = format(currentDate.value, 'yyyy-MM');
  const [year, month] = date.value.split('-');
  await getHdayModal(year, month);
};

// Selected days and days in month computation

const daysInMonth = computed(() => {
  const month = parseInt(currentMonth.value, 10) - 1; // Adjust month for Date object
  const year = parseInt(currentYear.value, 10);
  return Array.from({ length: new Date(year, month + 1, 0).getDate() }, (_, i) => (i + 1).toString().padStart(2, '0'));
});

const setHdayModal = async () => {
    try {
      const yearAndMonth = `${currentYear.value}-${currentMonth.value}`
      console.log(yearAndMonth)
      const response = await axios.post(`/api/setHdayModal/${yearAndMonth}`, { form: selectedDays.value });
      const result = response.data;
      if (result.success === true) {
      await getHday();
        dialog.value = false;
      } else {
        alert('휴일 추가에 실패하였습니다. 관리자에게 문의해주세요.');
      }
    } catch (error) {
      alert('휴일 추가를 처리할 수 없습니다.');
      console.error(error);
    }
};
const getHdayModal = async (year:any, month:any) => {
  if (loading.value) return;
  loading.value = true;
  try {
    const yearAndMonth = `${year}-${month}`
    const response = await axios.get(`/api/getHdayModal/${yearAndMonth}`);
    const responseData = response.data;
    if (responseData) {
      selectedDays.value = responseData
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  } finally {
    loading.value = false;
  }
};
const getHday = async () => {
  if (loading.value) return;
  loading.value = true;
  try {
    const response = await axios.get(`/api/getHday`);
    const responseData = response.data;
    if (responseData) {
      holidayList.value = responseData;
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  } finally {
    loading.value = false;
  }
};

onMounted(async() => {
  await getHday();
});
</script>

<style scoped>
.monthLabelBtn {
  background-color: #fff;
  color: #333;
  border: 1px solid rgb(203, 203, 203);
  font-size: 16px;
  min-width: 50px !important;
  max-height: 40px !important;
}

.btnBg {
  background: linear-gradient(45deg, #e89e7e, #ff9b70);
  color: #fff !important;
}
</style>