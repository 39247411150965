<template>
  <div class="rightSs">
      <v-btn class="rightSsBtn align-self-center me-4"
       variant="text"
        v-for="section in sections"
        :key="section.title"
          @click="loadComponent(section.link); setActiveItem(section)"
          :class="{ 'v-btn--active': isActiveLink(section.link) }"
          :prepend-icon="section.icon"
        >
          {{ section.title }}
      </v-btn>
    </div>
  <v-card class="clientRightCardHeight">
        <v-sheet rounded="lg">
        <!-- dynamicComponent 변수를 사용하며 디폴트 값으로 LaborCost를 설정 -->
        <component :is="dynamicComponent"></component>
      </v-sheet>
      </v-card>
</template>
<script setup lang="ts">
import store from '@/store';
import { computed, onMounted, ref, shallowRef, watch } from 'vue';
import {
  mdiFileSign,
  mdiIsland,
  mdiSack,
  mdiCurrencyKrw,
  mdiMonitorAccount,
  mdiInformationSlabCircleOutline,
} from '@mdi/js'
const dynamicComponent = shallowRef('SsEmpList');
const activeItem = shallowRef('SsEmpList');

const sections = ref([] as any[]);
if(store.state.role > 3){
  sections.value = [
    { title: '사원정보', link: 'SsEmpList', icon: mdiInformationSlabCircleOutline },
    { title: '임금기초', link: 'SsAttendance', icon: mdiMonitorAccount },
    { title: '임금대장', link: 'SsWage', icon: mdiCurrencyKrw },
    { title: '휴가관리', link: 'SsDayOff', icon: mdiIsland },
    { title: '퇴직급여', link: 'SsRetire', icon: mdiSack },
    { title: '계약서', link: 'SsContract', icon: mdiFileSign },
  ];
}else{
  sections.value = [
    { title: '사원정보', link: 'SsEmpList', icon: mdiInformationSlabCircleOutline },
    { title: '임금기초', link: 'SsAttendance', icon: mdiMonitorAccount },
    // { title: '임금대장', link: 'SsWage', icon: mdiCurrencyKrw },
    // { title: '휴가관리', link: 'SsDayOff', icon: mdiIsland },
    // { title: '퇴직급여', link: 'SsRetire', icon: mdiSack },
    // { title: '계약서', link: 'SsContract', icon: mdiFileSign },
  ];
}

const loadComponent = async (componentName: string) => {
  // 비동기적으로 컴포넌트를 불러옴
  const { default: Component } = await import(`@/components/sisul_emp/${componentName}.vue`);
  // 불러온 컴포넌트를 동적으로 설정
  dynamicComponent.value = Component;
};
const setActiveItem = (item: any) => {
  store.commit('setMngTitle', item);
  activeItem.value = item.link;
};
const isActiveLink = (link: string) => {
  return activeItem.value === link;
};
const changeMngTitle = computed(() => {
  const MngTitleComputed = store.state.MngTitle
  return MngTitleComputed;
});
watch(changeMngTitle, async (newValue:any) => {
  if (newValue) {
    const matchingSection = sections.value.find(section => section.link === newValue.link);
    if(matchingSection){
      activeItem.value = newValue.link;
      loadComponent(newValue.link);
    }else{
      return;
    }
  }
});
onMounted( async () => {
  await loadComponent(store.state.MngTitle.link);
  activeItem.value = store.state.MngTitle.link;
});
</script>