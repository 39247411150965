<template>
  <v-card class="normalMngCard" style="width: 100%;">
    <v-data-table fixed-header density="compact" scrollable :headers="headersArr" :items="filteredItems"
      :search="search" item-value="name" class="fontNotoSans400" style="
        height: calc(100vh - 217px); 

        overflow-y: hidden; 
        position: relative;
      " :items-per-page="-1" :items-per-page-options="[
        // { value: 50, title: '50' },
        // { value: 100, title: '100' },
        // { value: 200, title: '200' },
        { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
      ]" v-if="allClientList.length !== 0">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="fontNotoSans700">📑 상세진단 요청·진행 기관</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field class="inoutSearchInput" style="
        background-color: #fff;
          max-width:200px; 
          width:auto; 
          min-width:70px;
          margin: 0 20px 0 0;
        " v-model="search" color="#0f7545" label="기관, 지역 검색" single-line clearable :append-inner-icon="mdiMagnify" flat
            hide-details="auto" variant="outlined" density="compact">
          </v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item, index }">
        <tr class="datatableTr">
          <td class="tac">{{ index + 1 }}</td>
          <td class="tac">{{ item.fwa_upload_date || '' }}</td>
          <td class="">

            <v-chip @click="getOneCient(item)" style="cursor: pointer;" variant="text" label class="ma-2" color="black">
             
          <v-chip class="tar" color="orange " label v-if="item.comp_gubun === '주간보호'">
            주간
          </v-chip>
          <v-chip class="tar" color="blue" label v-else-if="item.comp_gubun === '요양시설'">
            요양
          </v-chip>
          <v-chip class="tar" color="blue" label v-else>
          </v-chip>
          <span style="margin-left: 5px;">{{ item.comp_name || '' }}</span>
            </v-chip>
            
          </td>
          <td class="tac">{{item.sido_name}}/{{ item.sigungu_name || '' }}</td>
          <td class="tac">{{ item.fwa_come_way || '' }}</td>

          <td class="tac">{{ item.client_name || '' }} / {{ item.client_phone || '' }}<br>{{ item.client_email || '' }}
          </td>
          <td class="tac" v-if="item.fwa_manager && mngNameList.includes(item.fwa_manager)">
            <span @click="changeMngDialog(item)">{{ item.fwa_manager }}</span>
          </td>

          <td class="tac" v-else-if="item.fwa_manager && !mngNameList.includes(item.fwa_manager)">
            <v-btn class="btnColorRed" @click="changeMngDialog(item)">담당신청: {{ item.fwa_manager }}</v-btn>
          </td>

          <td class="tac" v-else><v-btn class="btnColorRed" @click="changeMngDialog(item)">담당자없음</v-btn></td>

          <td class="tac">
            <v-chip color="red-accent-4 " v-if="item.fwa_status === 0">
              {{ item.fwa_status_case || '' }}
            </v-chip>
            <v-chip color="red-accent-4" v-else-if="item.fwa_status === 1">
              {{ item.fwa_status_case || '' }}
            </v-chip>
            <v-chip color="blue-darken-4 " v-else-if="item.fwa_status === 2">
              {{ item.fwa_status_case || '' }}
            </v-chip>
            <v-chip color="green-darken-4" v-else-if="item.fwa_status === 3">
              {{ item.fwa_status_case || '' }}
            </v-chip>
            <v-chip color="purple-accent-2 " v-else-if="item.fwa_status === 4">
              {{ item.fwa_status_case || '' }}
            </v-chip>
            <v-chip color="orange-accent-4" v-else-if="item.fwa_status === 5">
              {{ item.fwa_status_case || '' }}
            </v-chip>
            <v-chip color="cyan-darken-1 " v-else-if="item.fwa_status === 6">
              {{ item.fwa_status_case || '' }}
            </v-chip>

            <br>
            <div v-if="item.fwa_status === 5 || item.fwa_status === 6">
              {{ item.fwa_req_wage || '' }}
              {{ item.fwa_req_fna || '' }}
              {{ item.fwa_req_meal || '' }}
            </div>
            <div v-if="item.fwa_status === 0 || item.fwa_status === 1">
              {{ item.Elapsed_time }}시간 경과
            </div>
          </td>
          <td class="tac">
            <v-icon @click="downloadExcelFile(item.fwa_file_name)"
              :style="{ cursor: 'pointer', color: iconColor(item.fwa_file_name) }">

              {{ getFileIcon(item.fwa_file_name) }}
            </v-icon>
          </td>
          <td class="tac"><MakePdf :item="item"/></td>

        </tr>
      </template>

    </v-data-table>
  </v-card>
  <v-dialog v-model="dialog" height="800px" style="width: 300px;" class="fontNotoSans400" persistent>
    <v-card style="padding: 20px !important;"> <!--elevation : 그림자-->
      <h4 class="tac" style="margin-bottom: 10px;">담당자변경</h4>
      <select 
        style="cursor: pointer; width: 100%;" 
        v-model="selectMng" 
        class="mg-right10 mngSelect"
      >
        <option v-if="!selectMng" value="" class="tac">--- 선택 ---</option>
        <option class="tac" v-for="mng in mngNameList" :key="mng" :value="mng">
          {{ mng }}
        </option>
      </select>
      <v-btn @click="changeMng();" class="cancelRegistBtn smallDialogBtn">저장</v-btn>
      <v-btn @click="dialog = false" class="cancelRegistBtn smallDialogBtn">취소</v-btn>
    </v-card>
  </v-dialog>

</template>
<script setup lang="ts">
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import {
  mdiFileExcel,
  mdiFileDocument,
  mdiFilePdfBox,
  mdiFileImage,
  mdiFile,
  mdiCancel,
  mdiMagnify,
} from '@mdi/js'
import store from '@/store';
import router from '@/router';
import MakePdf from '@/components/pdf/MakePdf.vue'

const allClientList = ref([] as any[])
const mngNameList = ref([] as any[]);
const iconColor = (fileName: any) => {
  if (!fileName) {
    return 'red';
  }
  const extension = fileName.split('.').pop(); // 확장자 추출
  switch (extension) {
    case 'xls':
    case 'xlsx':
      return 'darkgreen';
    case 'pdf':
      return 'red';
    case 'hwp':
    case 'hwpx':
    case 'hwt':
      return 'orange';
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'tiff':
      return 'blue';
    default:
      return 'black';
  }
};
const getFileIcon = (fileName: any) => {
  if (!fileName) {
    return mdiCancel;
  }
  const extension = fileName.split('.').pop(); // 확장자 추출
  switch (extension) {
    case 'xls':
    case 'xlsx':
      return mdiFileExcel;
    case 'pdf':
      return mdiFilePdfBox;
    case 'hwp':
    case 'hwpx':
    case 'hwt':
      return mdiFileDocument;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'tiff':
      return mdiFileImage;
    default:
      return mdiFile;
  }
};
const search = ref('');
const headersArr = ref([
  { title: 'No.', key: 'index', sortable: false },
  { title: '요청일자', key: 'fwa_upload_date', align: 'center', sortable: true },
  { title: '기관 명', key: 'comp_name', align: 'center', sortable: true },
  { title: '지역', key: 'sigungu_name', align: 'center', sortable: true },
  { title: '경로', key: 'fwa_come_way', align: 'center', sortable: true },
  { title: '신청자', key: 'client_phone', align: 'center', sortable: true },
  { title: '담당자', key: 'fwa_manager', align: 'center', sortable: true },
  { title: '진단상태', key: 'fwa_status', align: 'center', sortable: true },
  { title: '업로드파일', key: 'fwa_file_name', align: 'center', sortable: true },
  { title: '보고서', key: '', align: 'center', sortable: true },
] as any[]);


const filteredItems = computed(() => {
  // 검색어가 비어 있으면 전체 리스트 반환
  if (!search.value) {
    return allClientList.value;
  }
  return allClientList.value.filter(item =>
    (item.comp_name && item.comp_name.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.sigungu_name && item.sigungu_name.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.sido_name && item.sido_name.toLowerCase().includes(search.value.toLowerCase()))
  );
});

const downloadExcelFile = (fileName: any) => {
  if (!fileName) {
    return;
  }
  window.location.href = `/api/downloadTemplateExcel/${fileName}`;
};

const getOneCient = async (item: any) => {
  store.commit('setMngOneClient', item);
  router.push('/MngOneClientPage');
}

///////////담당자변경로직////////////////////////////////
const dialog = ref(false);
const selectMng = ref('')
const selectFwaCode = ref('')
const changeMngDialog = (item: any) => {
  selectMng.value = item.fwa_manager
  selectFwaCode.value = item.fwa_code;
  dialog.value = true;
}
const changeMng = async () => {
  const isConfirmed = window.confirm(`담당자를 ${selectMng.value}님으로 변경하시겠습니까?`);
  if (isConfirmed) {
    try{
      const response = await axios.post(`/api/changeMng/${selectFwaCode.value}/${selectMng.value}`)
      const responseData = response.data;
      if (responseData.success) {
          await readAllClient();
        } else {
          console.log('저장에 실패하였습니다.')
      }
    }catch{
      console.error('에러가 발생했습니다.');
    }finally{
      dialog.value = false;
    }
  }
}

const getMngName = async () => {
  try {
    const response = await axios.get(`/api/getMngName`);
    const responseData = response.data;
    if (responseData) {

      const fwaManagers = responseData.map((item: { hm_name: any; }) => item.hm_name);
      mngNameList.value = fwaManagers;

      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};
////////////////////////////////////////////////////////

const readAllClient = async () => {
  try {
    const response = await axios.get(`/api/readAllClient`);
    const responseData = response.data;
    if (responseData) {
      allClientList.value = responseData;
      
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};


onMounted(async () => {
  await readAllClient();
  await getMngName();
  selectMng.value= '';
  sessionStorage.removeItem('mngOneClient');
})
</script>