<template>
  <v-card class="normalMngCard" style="width: 100%;">
    <v-data-table v-if="!loading" fixed-header density="compact" scrollable :headers="headersArr" :items="filteredItems"
      :search="search" item-value="name" class="fontNotoSans400" style="
        height: calc(100vh - 217px); 
        overflow-y: hidden; 
        position: relative;
      " :items-per-page="200" :items-per-page-options="[
      { value: 200, title: '200' },
      { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
    ]">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="fontNotoSans700">📋 장기요양기관 목록</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <div v-if="role >= 8">
            <MailLinkClickLog/>
          </div>
          <v-menu offset-y v-if="role >= 8">
            <template v-slot:activator="{ props }">
              <v-btn class="btnColorDark" v-bind="props"> 메일 기능 ▾ </v-btn>
            </template>
            <v-list>
              <v-list-item @click="selectAllSendMail">
                <p class="fontNotoSans500">메일 전체 선택</p>
              </v-list-item>
              <v-list-item @click="selectJungwonSendMail(30)">
                <p class="fontNotoSans500">메일 30명 이상 선택</p>
              </v-list-item>
              <v-list-item @click="selectJungwonSendMail(40)">
                <p class="fontNotoSans500">메일 40명 이상 선택</p>
              </v-list-item>
              <v-list-item @click="removeMailSelect">
                <p class="fontNotoSans500">메일보낸기록 1이상 삭제</p>
              </v-list-item>
              <v-list-item @click="deleteAllSendMail">
                <p class="fontNotoSans500">메일 전체 삭제</p>
              </v-list-item>
              <v-list-item @click="mailDialogOpen">
                <p class="fontNotoSans500">메일발송 / {{ sendMailArrLength }}개 기관</p>
              </v-list-item>
              
            </v-list>
          </v-menu>

          <select v-model="imgName" style="outline: auto; padding: 3px 10px 3px 10px;" class="ml-2">
              <option v-for="status in imgNameValue" :key="status.value" :value="status.value">{{ status.label }}
              </option>
            </select>

          <v-divider class="mx-4" inset vertical></v-divider>
          
          <v-table style="border-radius: 4px;">
            <tbody>
              <tr>
                <td class="tac" style="border-right: 1px solid #d9d9d9;">
                  <span>시·도</span>
                  <br>
                  <span>시·군·구</span>
                </td>
                <td style="border-right: 1px solid #d9d9d9; padding: 0;">
                  <select style="cursor: pointer; width: 100%;" v-model="custSearchForm.sido" @change="getSigungu()"
                    class="mg-right10">
                    <option value="" class="tac">전체</option>
                    <option class="tac" v-for="sido in sidoInfo" :key="sido.sido_id" :value="sido.sido_id">
                      {{ sido.sido_name }}
                    </option>
                  </select>
                  <br>
                  <select style="cursor: pointer; width: 100%;" v-model="custSearchForm.sigungu">
                    <option value="" class="tac">전체</option>
                    <option class="tac" v-for="sigungu in sigunguInfo" :key="sigungu.sigungu_id"
                      :value="sigungu.sigungu_id">{{ sigungu.sigungu_name }}
                    </option>
                  </select>
                </td>
                <td class="tac">
                  <input type="checkbox" v-model="custSearchForm.biz_gubunYo" value="요양시설"> 요양시설
                  <br>
                  <input type="checkbox" v-model="custSearchForm.biz_gubunJu" value="주간보호"> 주간보호
                </td>
                <td>
                  <v-btn class="btnColorDark" @click="readCust">검색</v-btn>
                </td>
              </tr>
            </tbody>
          </v-table>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field class="inoutSearchInput" style="
        background-color: #fff;
          max-width:200px; 
          width:auto; 
          min-width:70px;
          margin: 0 20px 0 0;
        " v-model="search" color="#0f7545" label="기관검색" single-line clearable :append-inner-icon="mdiMagnify" flat
            hide-details="auto" variant="outlined" density="compact">
          </v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item, index }">
        <tr class="datatableTr">
          <td class="tac">{{ index + 1 }}</td>
          <td>
            <v-chip variant="text" label class="ma-2" color="black">
              <v-chip class="tal" color="orange " label v-if="item.hc_biz_gubun === '주간보호'">
                주간
              </v-chip>
              <v-chip class="tal" color="blue" label v-else-if="item.hc_biz_gubun === '요양시설'">
                요양
              </v-chip>
              <v-chip class="tal" color="blue" label v-else>
              </v-chip>
              <span style="margin-left: 5px; text-align: center;">{{ item.hc_name || '' }}</span>
            </v-chip>
            <v-icon class="tar mr-1" @click="wageImg(item)" size="x-large" style="color: blue;">{{ mdiFileImage }}</v-icon>
            인쇄용<v-icon class="tar" @click="wageImg_dialog_print(item)" size="x-large" style="color: rgb(11, 11, 11);"> {{ mdiFileImage }}</v-icon>
            
          </td>
          <td v-if="!item.editing" class="tac">{{ item.hc_hyunwon }}명/{{ item.hc_jungwon }}명</td>
          <td v-if="item.editing" style="max-width: 70px;">
            <v-text-field 
              type="number" 
              label="현원"
              variant="underlined"
              v-model="item.hc_hyunwon" 
              hide-details="auto"
              style="display: inline;"
            />
            <v-text-field 
              type="number" 
              label="정원"
              variant="underlined"
              v-model="item.hc_jungwon"
              hide-details="auto"
              style="display: inline;"

            />
          </td>
          <td v-if="!item.editing" class="tac">{{ item.hc_nursing_care }}명</td>
          <td v-if="item.editing" style="max-width: 70px;">
            <v-text-field 
              type="number" 
              label="요양보호사"
              variant="underlined"
              v-model="item.hc_nursing_care" 
              hide-details="auto"
            />
          </td>
          <td v-if="!item.editing" class="tac">{{ item.hc_tell || 'x' }}<br>{{ item.hc_email || 'x' }}</td>
          <td v-if="item.editing">
            <v-text-field 
              type="text" 
              label="전화번호( - 포함)"
              variant="underlined"
              v-model="item.hc_tell" 
              hide-details="auto"
              maxlength="13"
            />
            <br>
            <v-text-field 
              type="text" 
              label="이메일"
              variant="underlined"
              v-model="item.hc_email"
              hide-details="auto"
              maxlength="50"
            />
          </td>
          <td v-if="!item.editing" class="tac">{{ item.hc_fax || 'x'}}</td>
          <td v-if="item.editing" style="max-width: 70px;">
            <v-text-field 
              type="text" 
              label="팩스번호"
              variant="underlined"
              v-model="item.hc_fax"
              hide-details="auto"
              maxlength="20"
            />
          </td>
          <td class="tac">최대:{{ formatToKRW(item.hc_labor_max) || '계산불가' }}<br>평균:{{ formatToKRW(item.hc_labor_avg) || '계산불가' }}</td>
          <td class="tac">최대:{{ formatToKRW(item.hc_comp_max) || '계산불가' }}<br>평균:{{ formatToKRW(item.hc_comp_avg) || '계산불가' }}</td>
         
          <td class="tac" v-if="!item.hc_duplicate_name && role >= 8">
              <template v-if="!item.mailSelect">
                <v-icon @click="toggleMailMode(item)" color="orange-darken-2" size="x-large">{{ mdiEmailFastOutline }}</v-icon>
              </template>
              <template v-else>
                <v-btn @click="cancelMail(item)" class="btnColorDark" style="height: 28px; margin-left: 5px;">취소</v-btn>
              </template>
              <br> 
              <b @click="toggleMailMode(item)" v-if="item.countMailLog" style="cursor: pointer;">{{ item.countMailLog }} 건</b>
              <b @click="toggleMailMode(item)" v-else  style="cursor: pointer;">0 건</b>
          </td>
          <td class="tac" v-if="item.hc_duplicate_name === 'Y' && role >= 8">
            <b>발송불가</b>
          </td>
          <td class="tac">
            <template v-if="!item.editing">
              <v-icon @click="toggleEditMode(item)" color="purple-darken-2" size="x-large">{{ mdiPen }}</v-icon>
            </template>
            <template v-else>
              <v-btn @click="updateCustInfo(item)" class="btnColorBlue" style="height: 28px;">수정</v-btn>
              <v-btn @click="cancelEdit(item)" class="btnColorDark" style="height: 28px; margin-left: 5px;">취소</v-btn>
            </template>

          </td>
        </tr>
      </template>

    </v-data-table>
    <div v-if="loading" class="spinner tac"></div>

  </v-card>

  <v-dialog v-model="dialog" style="width: 852px;" class="fontNotoSans700" >
    <v-card style="text-align: center; padding: 20px 40px;">
      <h2 class="tac">{{ wageImgCompName }}</h2>
      <v-row class="wageChartRow">
              <v-col cols="12" md="6" sm="10" xs="12" class="wageChartCol">
                <v-card class="steppersImgCard overflow-visible position-relative" elevation="2" variant="elevated">
                  <img class="man1" src="@/assets/pictures/man1.png" />
                  <div class="contentComp">

                    <div class="stepper2MentBDiv">
                      <b class="stepper2MentB" style="color: #593CBA;">기관 </b><span class="stepper2MentSpan">인건비
                        절감금액</span>
                    </div>
                    <div>
                      <span>{{ formatToKRW(comp_avg) }} ~ </span>
                      <b class="stepper2MentB" style="color: #593CBA;">{{ formatToKRW(comp_max) }}</b>
                    </div>
                    <div class="stepper2MentChipDiv">
                      <v-chip class="stepper2MentChip blue-darken-4" variant="tonal" size="large" elevation="0">
                        연간 인건비 절감 예상금액
                      </v-chip>
                    </div>
                  </div>
                </v-card>
              </v-col>
              
              <v-col cols="12" md="6" sm="10" xs="12" class="wageChartCol">
                <v-card class="steppersImgCard overflow-visible position-relative" elevation="2" variant="elevated">
                  <img class="woman1" src="@/assets/pictures/woman1.png" />
                  <div class="contentLabor">

                    <div class="stepper2MentBDiv">
                      <b class="stepper2MentB" style="color: #d66503;">근로자 </b><span class="stepper2MentSpan">실수령액
                        증가</span>
                    </div>
                    <div>
                      <span>{{ formatToKRW(labor_avg) }} ~ </span>
                      <b class="stepper2MentB" style="color: #d66503;">{{ formatToKRW(labor_max) }}</b>
                    </div>
                    <div class="stepper2MentChipDiv">
                      <v-chip class="stepper2MentChip blue-darken-4" variant="tonal" size="large" elevation="0">
                        1인당 연간 실수령액
                      </v-chip>
                    </div>
                  </div>
                </v-card>
              </v-col>

              

              <v-col cols="12" md="12" sm="10" xs="12" class="wageChartCol">
                <v-card class="steppersImgCard overflow-visible position-relative" elevation="2" variant="elevated">
                  <img class="money1" src="@/assets/pictures/money1.png" />
                  <div class="contentPayBackMoney">
                    <div class="stepper2MentBDiv"><span class="stepper2MentSpan">더 낸 사회보험 예상 </span>
                      <b class="stepper2MentB" style="color: #FFBB22;">환급금 </b><span class="stepper2MentSpan"></span>
                    </div>
                    <div>
                      <span>{{ formatToKRW(payback_avg) }} ~ </span>
                      <b class="stepper2MentB" style="color: #FFBB22;">{{ formatToKRW(payback_max) }}</b>
                    </div>
                    <div class="stepper2MentChipDiv">
                      <v-chip class="stepper2MentChip blue-darken-4" variant="tonal" size="large" elevation="0">
                        {{ getPreviousMonth() }}
                      </v-chip>
                    </div>

                  </div>
                </v-card>
              </v-col>

            </v-row>
            <v-row class="wageChartRow" style="margin-top: 0 !important;">
              <v-col cols="12" md="12" sm="10" xs="12" class="wageChartCol" style="padding-top: 0 !important;">
                <v-card class="wageChartText">
                  <v-card-title class="tac mb-2">
                    <b>NOTICE</b>
                  </v-card-title>
                <span>1. 절감기준은 최신 소득세법, 근로기준법을 근거로 작성되었습니다.</span><br />
                <span>2. 위의 내용은 장기요양보험공단의 기관정보를 토대로 작성되었습니다.</span><br />
                <span>3. 해당 금액은 귀 기관과 규모가 비슷한 기관들의 평균 절감금액이오니 착오없으시기 바랍니다.</span>
                <p class="mt-2">* 더욱 자세한 내용을 원하신다면 하단의 '<b>다음</b>' 버튼을 클릭 후 '<b>상세진단신청</b>'을 완료해주세요. </p>
              </v-card>
              </v-col>
              
            </v-row>
      <v-btn @click="dialog = false" style="margin-top: 40px;" class="cancelRegistBtn">닫기</v-btn>

    </v-card>
  </v-dialog>
  <v-dialog v-model="mailDialog" fullscreen transition="dialog-bottom-transition"
  class="mailFromCompDialog fontNotoSans700" >
  <v-toolbar style="background-color: #333;">
    <v-spacer></v-spacer>
    <v-btn color="#fff" :icon="mdiClose" @click="mailDialog = false"></v-btn>
  </v-toolbar>
    <v-card style="text-align: center; padding: 20px 40px 80px 40px;">
      
      <v-row>
        <v-col cols="4" style="border-right: 2px solid #454545;">
          <table class="mailSelectCompTb" v-if="sendMailArr.length < 1000">
          <tbody>
          <tr>
            <td>총 : {{sendMailArr.length}} 개</td>
          </tr>
          <tr v-for="(item,index) in sendMailArr" :key="item.hc_idx">
            <td class="tal">{{ index + 1 }}. </td>
            <td class="tal">{{ item.hc_name }}</td>
            <td class="tar">
              <v-icon v-if="item.mailSendStatus">{{mdiCheckDecagram}}</v-icon>
              <v-icon v-if="!loading" @click="toggleMailMode(item)">{{mdiTrashCanOutline}}</v-icon>
            </td>
          </tr>
        </tbody>
        </table>
        <p v-else>총 : {{sendMailArr.length}} 개 기관에게 메일을 발송합니다.</p>
        </v-col>
        <v-col cols="8">
          <div v-if="loading" style="height: 100vh;">
    <div class="spinner tac"></div>
  </div>

  <div v-else>
    <div class="mb-5" style="display: flex; align-items: center; justify-content: center;">
      <table>
        <tbody>
          <tr>
            <td>
              <b class="mr-2">구분</b>

            </td>
            <td style="min-width: 250px;">
              <select v-model="imgName" style="outline: auto; padding: 3px 20px 3px 20px;">
              <option v-for="status in imgNameValue" :key="status.value" :value="status.value">{{ status.label }}
              </option>
            </select>
            </td>
          </tr>
          <tr>
            <td>
              메일제목
            </td>
            <td style="min-width: 250px;">
              <v-text-field v-model="subject" placeholder="이곳에 제목을 입력해주세요." type="text" hide-details="auto" variant="outlined" density="compact"
              />
            </td>
          </tr>
        </tbody>
      </table>
     
      <v-divider class="mx-4" inset vertical></v-divider>

      <!-- <v-btn @click="sendMailForComp" class="ml-2 btnColorGreen bigBtn2">메일발송</v-btn> -->
      <v-btn @click="sendScheduler" v-if="schedulerStatus === false" class="ml-2 btnColorPink bigBtn2">스케쥴러</v-btn>
      <v-btn @click="stopScheduler" v-if="schedulerStatus === true" class="ml-2 btnColorWhite bigBtn2">스케쥴러중지</v-btn>
    </div>

    <v-sheet style="max-width: 600px !important; margin: auto;">
      <b v-if="imageUrl === 'nothing'">업로드된 파일이 존재하지 않습니다.</b>
      <img style="width: 100%;" v-else :src="imageUrl" alt="Promotional Image" />
    </v-sheet>
  </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>







  <v-dialog v-model="dialog_print" fullscreen style="width: 100vw; height: calc(100vh-100px);" class="fontNotoSans700" >
    <v-card style="text-align: center; padding: 0 40px;">
      <h2 class="tac">{{ wageImgCompName }}</h2>
      <v-row class="wageChartRow">
              <v-col cols="12" md="6" sm="10" xs="12" class="wageChartCol">
                <v-card class="steppersImgCard2 overflow-visible position-relative" elevation="2" variant="elevated">
                  <img class="man1" src="@/assets/pictures/man1.png" />
                  <div class="contentComp">

                    <div class="stepper2MentBDiv">
                      <b class="stepper2MentB" style="color: #593CBA;">기관 </b><span class="stepper2MentSpan">인건비
                        절감금액</span>
                    </div>
                    <div>
                      <span>{{ formatToKRW(comp_avg) }} ~ </span>
                      <b class="stepper2MentB" style="color: #593CBA;">{{ formatToKRW(comp_max) }}</b>
                    </div>
                    <div class="stepper2MentChipDiv">
                      <v-chip class="stepper2MentChip blue-darken-4" variant="tonal" size="large" elevation="0">
                        연간 인건비 절감 예상금액
                      </v-chip>
                    </div>
                  </div>
                </v-card>
              </v-col>
              
              <v-col cols="12" md="6" sm="10" xs="12" class="wageChartCol">
                <v-card class="steppersImgCard2 overflow-visible position-relative" elevation="2" variant="elevated">
                  <img class="woman1" src="@/assets/pictures/woman1.png" />
                  <div class="contentLabor">

                    <div class="stepper2MentBDiv">
                      <b class="stepper2MentB" style="color: #d66503;">근로자 </b><span class="stepper2MentSpan">실수령액
                        증가</span>
                    </div>
                    <div>
                      <span>{{ formatToKRW(labor_avg) }} ~ </span>
                      <b class="stepper2MentB" style="color: #d66503;">{{ formatToKRW(labor_max) }}</b>
                    </div>
                    <div class="stepper2MentChipDiv">
                      <v-chip class="stepper2MentChip blue-darken-4" variant="tonal" size="large" elevation="0">
                        1인당 연간 실수령액
                      </v-chip>
                    </div>
                  </div>
                </v-card>
              </v-col>

              

              <v-col cols="12" md="12" sm="10" xs="12" class="wageChartCol">
                <v-card class="steppersImgCard2 overflow-visible position-relative" elevation="2" variant="elevated">
                  <img class="money1" src="@/assets/pictures/money1.png" />
                  <div class="contentPayBackMoney">
                    <div class="stepper2MentBDiv"><span class="stepper2MentSpan">더 낸 사회보험 예상 </span>
                      <b class="stepper2MentB" style="color: #FFBB22;">환급금 </b><span class="stepper2MentSpan"></span>
                    </div>
                    <div>
                      <span>{{ formatToKRW(payback_avg) }} ~ </span>
                      <b class="stepper2MentB" style="color: #FFBB22;">{{ formatToKRW(payback_max) }}</b>
                    </div>
                    <div class="stepper2MentChipDiv">
                      <v-chip class="stepper2MentChip blue-darken-4" variant="tonal" size="large" elevation="0">
                        {{ getPreviousMonth() }}
                      </v-chip>
                    </div>

                  </div>
                </v-card>
              </v-col>

            </v-row>
            <v-row class="wageChartRow" style="margin-top: 0 !important;">
              <v-col cols="12" md="12" sm="10" xs="12" class="wageChartCol" style="padding-top: 0 !important;">
                <v-card class="wageChartText">
                  <v-card-title class="tac mb-2">
                    <b>NOTICE</b>
                  </v-card-title>
                <span>1. 절감기준은 최신 소득세법, 근로기준법을 근거로 작성되었습니다.</span><br />
                <span>2. 위의 내용은 장기요양보험공단의 기관정보를 토대로 작성되었습니다.</span><br />
                <span>3. 해당 금액은 귀 기관과 규모가 비슷한 기관들의 평균 절감금액이오니 착오없으시기 바랍니다.</span>
                <p class="mt-2">* 더욱 자세한 내용을 원하신다면 하단의 '<b>다음</b>' 버튼을 클릭 후 '<b>상세진단신청</b>'을 완료해주세요. </p>
              </v-card>
              </v-col>
              
            </v-row>
      <v-btn @click="dialog = false" style="margin-top: 40px;" class="cancelRegistBtn"></v-btn>

    </v-card>
  </v-dialog>







</template>
<script setup lang="ts">
import MailLinkClickLog from '@/components/mng/mail/MailLinkClickLog.vue'
import { computed, onMounted, reactive, ref, watch } from 'vue';
import {
  mdiMagnify,
  mdiFileImage,
  mdiPen,
  mdiEmailFastOutline,
  mdiCheckDecagram,
  mdiTrashCanOutline,
  mdiClose
} from '@mdi/js'
import axios from 'axios';
import store from '@/store';
const mailDialog =ref(false);
const mailDialogOpen = () => {
  mailDialog.value = true;
  fetchImage();
}
const custList = ref([] as any[])
const sidoInfo = ref([] as any);
const sigunguInfo = ref([] as any);
const sendMailArr = ref([] as any[]);
const userId = store.state.id;
const role = store.getters.role;

const toggleMailMode = (item: any) => {
  item.mailSelect = !item.mailSelect;
  if (item.mailSelect) {
    sendMailArr.value.push(item);
    item.mailSendStatus = false;
  } else {
    sendMailArr.value = sendMailArr.value.filter((i: any) => i !== item);
  }
};

const cancelMail = (item: any) => {
  item.mailSelect = false; // 수정 모드 종료
  sendMailArr.value = sendMailArr.value.filter((i: any) => i !== item);
};

const sendMailArrLength = computed(() => sendMailArr.value.length);

const search = ref('');
let headersArr = ref([] as any[]);
if(role >= 8){
  headersArr.value = [{ title: 'No.', key: 'index', align: 'center', sortable: false },
  { title: '기관명', key: 'hc_name', align: 'center', sortable: true },
  { title: '수급자', key: 'hc_jungwon', align: 'center', sortable: true },
  { title: '요양보호사', key: 'hc_nursing_care', align: 'center', sortable: true },
  { title: '연락처', key: 'hc_tell', align: 'center', sortable: true },
  { title: '팩스번호', key: 'hc_fax', align: 'center', sortable: true },
  { title: '근로자1인', key: 'hc_labor_max', align: 'center', sortable: true },
  { title: '기관인건비절감', key: 'hc_comp_max', align: 'center', sortable: true },
  { title: '메일발송', key: 'countMailLog', align: 'center', sortable: true },
  { title: '수정', key: '', align: 'center', sortable: true }]
}else{
  headersArr.value = [{ title: 'No.', key: 'index', align: 'center', sortable: false },
  { title: '기관명', key: 'hc_name', align: 'center', sortable: true },
  { title: '수급자', key: 'hc_jungwon', align: 'center', sortable: true },
  { title: '요양보호사', key: 'hc_nursing_care', align: 'center', sortable: true },
  { title: '연락처', key: 'hc_tell', align: 'center', sortable: true },
  { title: '팩스번호', key: 'hc_fax', align: 'center', sortable: true },
  { title: '근로자1인', key: 'hc_labor_max', align: 'center', sortable: true },
  { title: '기관인건비절감', key: 'hc_comp_max', align: 'center', sortable: true },
  { title: '수정', key: '', align: 'center', sortable: true }]
}


const filteredItems = computed(() => {
  // 검색어가 비어 있으면 전체 리스트 반환
  if (!search.value) {
    return custList.value;
  }
  return custList.value.filter(item =>
    (item.hc_name && item.hc_name.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.hc_biz_gubun && item.hc_biz_gubun.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.hc_addr && item.hc_addr.toLowerCase().includes(search.value.toLowerCase()))
  );
});

const selectJungwonSendMail = (number : number) => {
  // 필터링된 항목들을 가져옴
  const filtered = custList.value.filter(item =>
  // 중복 이름이 없거나, 값이 'Y'가 아닌 경우 필터링
  (!item.hc_duplicate_name || item.hc_duplicate_name !== 'Y') && (
      (item.hc_name && item.hc_name.toLowerCase().includes(search.value.toLowerCase())) ||
      (item.hc_biz_gubun && item.hc_biz_gubun.toLowerCase().includes(search.value.toLowerCase())) ||
      (item.hc_addr && item.hc_addr.toLowerCase().includes(search.value.toLowerCase()))
    ) &&
    item.hc_jungwon >= number && item.hc_email
  );

  sendMailArr.value.filter(item => {
    item.mailSelect = false;
  })
  sendMailArr.value = [];

  // 필터링된 항목들을 sendMailArr에 추가
  sendMailArr.value.push(...filtered);

  // 중복된 항목을 제거 (hc_email을 기준으로)
  const uniqueEmails = new Set();
  sendMailArr.value = sendMailArr.value.filter(item => {
    if (!uniqueEmails.has(item.hc_email)) {
      uniqueEmails.add(item.hc_email);
      return true;
    }
    return false;
  });

  // 선택 상태 변경
  sendMailArr.value.forEach(item => {
    item.mailSelect = true;
  });

  // 디버깅용 콘솔 로그
};


const selectAllSendMail = () => {
  // 필터링된 항목들을 가져옴
  const filtered = custList.value.filter(item =>
  (!item.hc_duplicate_name || item.hc_duplicate_name !== 'Y') &&

    ((item.hc_name && item.hc_name.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.hc_biz_gubun && item.hc_biz_gubun.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.hc_addr && item.hc_addr.toLowerCase().includes(search.value.toLowerCase()))) &&
    item.hc_email
  );
  // 필터링된 항목들을 sendMailArr에 추가
  sendMailArr.value.push(...filtered);

  // 중복된 항목을 제거 (hc_email을 기준으로)
  const uniqueEmails = new Set();
  sendMailArr.value = sendMailArr.value.filter(item => {
    if (!uniqueEmails.has(item.hc_email)) {
      uniqueEmails.add(item.hc_email);
      return true;
    }
    return false;
  });
  sendMailArr.value.filter(item => {
    item.mailSelect = true;
  })
  // 디버깅용 콘솔 로그
};

const deleteAllSendMail = () => {
  sendMailArr.value.filter(item => {
    item.mailSelect = false;
  })
  sendMailArr.value = []; 
}


const custSearchForm = reactive({
  sido: '',
  sigungu: '',
  biz_gubunYo: '요양시설',
  biz_gubunJu: ''
});
///////////모달이미지///////////////////////////////////
const dialog = ref(false);
//환급적용기간
const getPreviousMonth = () => {
  const today = new Date();
  today.setMonth(today.getMonth() - 1);  // 한 달 전으로 설정
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0') as any;  // 월을 2자리 문자열로 변환 (1월은 0부터 시작하므로 +1)
  return `${year}.01 ~ ${year}.${month} (${month - 1 + 1}개월 간)`;
};
//환급액 계산
const calcMonth = () => {
  const today = new Date();
  today.setMonth(today.getMonth() - 1);  // 한 달 전으로 설정
  const month = (today.getMonth() + 1);  // 월을 2자리 문자열로 변환 (1월은 0부터 시작하므로 +1)
  return `${month/12}`;
};
let calcMonthValue = ref(0);
let labor_max = ref(0);
let labor_avg = ref(0);
let comp_max = ref(0);
let comp_avg = ref(0);
let payback_max = ref(0);
let payback_avg =  ref(0);
let wageImgCompName = ref('');
const subject = ref('');
const dialog_print = ref(false);
const wageImg_dialog_print = (item: any) => {
  wageImgCompName.value = item.hc_name;
  dialog_print.value = true;
  let laborPer = 0
  let compPer = 0
  let nursing = item.hc_nursing_care;
  if (nursing < (item.hc_hyunwon / 2.5 * 0.6)) {
    nursing = item.hc_hyunwon / 2.5
  }

  if (item.hc_biz_gubun === '요양시설') {
    laborPer = 38.8;
    compPer = 58.7;
  } else {
    laborPer = 25.5;
    compPer = 48;
  }
  calcMonthValue.value = parseFloat(calcMonth());
  labor_max.value = 620000;
  labor_avg.value = Math.floor(620000 * laborPer / 100);
  comp_max.value = 696000 * nursing;
  comp_avg.value = Math.floor(696000 * compPer / 100 * nursing);
  payback_avg.value = (comp_avg.value + labor_avg.value) * calcMonthValue.value;
  payback_max.value = (comp_max.value + labor_max.value) * calcMonthValue.value;
}
const wageImg = (item: any) => {
  wageImgCompName.value = item.hc_name;
  dialog.value = true;
  let laborPer = 0
  let compPer = 0
  let nursing = item.hc_nursing_care;
  if (nursing < (item.hc_hyunwon / 2.5 * 0.6)) {
    nursing = item.hc_hyunwon / 2.5
  }

  if (item.hc_biz_gubun === '요양시설') {
    laborPer = 38.8;
    compPer = 58.7;
  } else {
    laborPer = 25.5;
    compPer = 48;
  }
  calcMonthValue.value = parseFloat(calcMonth());
  labor_max.value = 620000;
  labor_avg.value = Math.floor(620000 * laborPer / 100);
  comp_max.value = 696000 * nursing;
  comp_avg.value = Math.floor(696000 * compPer / 100 * nursing);
  payback_avg.value = (comp_avg.value + labor_avg.value) * calcMonthValue.value;
  payback_max.value = (comp_max.value + labor_max.value) * calcMonthValue.value;
}
////////////////////////////////////////////////////////

const toggleEditMode = (item: any) => {
  item.editing = !item.editing;
};
// 수정 취소하는 메서드
const cancelEdit = (item: any) => {
  item.editing = false; // 수정 모드 종료
};


function formatToKRW(number: number) {
  // 10,000 단위로 나누고 정수로 반올림
  const millionWon = Math.round(number / 10000);
  // '만원'을 붙여서 문자열로 반환
  return new Intl.NumberFormat().format(millionWon) + '만원';

}
const getSido = async () => {
  try {
    const resSido = await axios.get(`/api/getSido/`);
    const SidoData = resSido.data;
    if (SidoData) {
      sidoInfo.value = SidoData
      return SidoData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};
const getSigungu = async () => {
  custSearchForm.sigungu = '';
  if(custSearchForm.sido === ''){
    return;
  }
  try {
    const resSigungu = await axios.get(`/api/getSigungu/${custSearchForm.sido}`);
    const SigunguData = resSigungu.data;
    if (SigunguData) {
      sigunguInfo.value = SigunguData
      return SigunguData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};
const loading = ref(false);

const readCust = async () => {
  let sido = custSearchForm.sido;
  let sigungu = custSearchForm.sigungu;
  let biz_gubunYo = custSearchForm.biz_gubunYo;
  let biz_gubunJu = custSearchForm.biz_gubunJu;

  if (!sido) {
    sido = 'all';
    // alert('시·도를 선택해주세요.')
    // return;
  } else if (!biz_gubunYo && !biz_gubunJu) {
    alert('사업구분을 선택해주세요.')
    return;
  }
  if (!sigungu) {
    sigungu = 'all';
  }
  try {
    if (biz_gubunYo) {
      biz_gubunYo = '요양시설';
    } else {
      biz_gubunYo = '주간보호';
    }

    if (biz_gubunJu) {
      biz_gubunJu = '주간보호';
    } else {
      biz_gubunJu = '요양시설';
    }
    loading.value === true;
    const response = await axios.get(`/api/readCust/${sido}/${sigungu}/${biz_gubunYo}/${biz_gubunJu}`);

    const responseData = response.data;
    if (responseData) {
      custList.value = responseData;
      
      let Map = {} as any;  
      mailLog.value.forEach((item: { hc_number: string | number; countMailLog: any; }) => {
        Map[item.hc_number] = item.countMailLog;
      });

      custList.value.forEach(item => {
        if(Map[item.hc_number] !== undefined) {
          item.countMailLog = Map[item.hc_number];
        }else {
          item.countMailLog = 0;
        }
      })

      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  } finally {
    loading.value === false;
  }
};


const updateCustInfo = async (item: any) => {
  if (loading.value) return;
  const isConfirmed = window.confirm('정말 수정하시겠습니까?');
  if (isConfirmed) {
  loading.value = true;
  try {
    const response = await axios.post(`/api/updateCustInfo/`, { form: item });
    const result = response.data;
    if (result.success === true) {
      console.log('success')
    } else {
      alert('수정에 실패하였습니다. 관리자에게 문의해주세요.');
    }
  } catch (error) {
    alert('수정을 처리할 수 없습니다.');
    console.error(error);
  } finally {
    loading.value = false;
    item.editing = false;
    readCust();
  }
}else{
  return;
}
};
const removeMailSelect = () => {
  // countMailLog가 1 이상인 항목의 mailSelect 값을 false로 설정
  sendMailArr.value.forEach(item => {
    if (item.countMailLog >= 1) {
      item.mailSelect = false; // mailSelect 값을 false로 설정
    }
  });

  // mailSelect가 true인 항목만 남기고 배열에서 false인 항목을 삭제
  sendMailArr.value = sendMailArr.value.filter(item => item.mailSelect === true);

};

////////////////////////////////////////////////////////////////////////////////////////

//// 이메일 형식 검증을 위한 정규 표현식
// const isValidEmail = (email:any) => {
//   // 기본 이메일 형식 정규 표현식
//   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//   return emailPattern.test(email);
// };

// const sendMailForComp = async () => {
//   if(subject.value === ''){
//     alert('메일 제목을 입력해주세요.');
//     return;
//   }
//   const isConfirmed = window.confirm('메일을 발송하시겠습니까?');
//   if (isConfirmed) {
//     for (const m of sendMailArr.value) {
//         if(m.mailSendStatus === true){
//           alert('이미 같은 메일을 보낸 기관이 존재합니다.')
//           return;
//         }
//       }
//     try {
//       // 중복 이메일 제거
//     loading.value = true;

//       const uniqueEmails = new Set();
//       sendMailArr.value = sendMailArr.value.filter(item => {
//         if (!uniqueEmails.has(item.hc_email)) {
//           uniqueEmails.add(item.hc_email);
//           return true;
//         }
//         return false;
//       });

//       // 메일 발송 처리
//       for (const m of sendMailArr.value) {
//         // 이메일이 없거나 이메일 형식이 유효하지 않은 경우
//         if (!m.hc_email || !isValidEmail(m.hc_email)) {
//           continue; // 다음 항목으로 이동
//         }

//         const toMail = m.hc_email;
//         console.log('메일 발송할 이메일:', toMail);

//         try {
//           const subjectEncode = encodeURIComponent(subject.value)
//           const response = await axios.post(`/api/sendMailForComp/${toMail}/${subjectEncode}/${imgName.value}/${userId}/${m.hc_number}`);
//           const result = response.data;
//           if (result) {
//             m.mailSendStatus = true;
//           } else {
//             console.error('메일 전송에 실패하였습니다.', result);
//           }
//         } catch (error) {
//           alert(`${m.hc_name} 기관으로의 메일 전송이 실패하였습니다. 관리자에게 문의 바랍니다.`);
//           console.error('에러 메시지:', error);
//         }
//       }
//     } finally {
//       await custMailLog();
//       await readCust();
//       loading.value = false;
//       alert('메일 전송이 종료되었습니다.');
//     }
//   }
// };



const imgNameValue = ref([
  { label: '사회보험 환급 서비스 홍보', value: 'hamwooriPayBack' },
  { label: '급식 서비스 홍보', value: 'hamwooriMeal' },
  { label: '회계 서비스 홍보', value: 'hamwooriFna' },
  { label: '플랫폼 서비스 홍보', value: 'hamwooriPlatform' },
]);
const imageType = ref();
const imageUrl = ref();
const imgName = ref('');

const changeImg = computed(() => {
  const whatImgName = imgName.value
return whatImgName;
});

watch(changeImg, async (newValue : any) => {
  if (newValue && newValue !== 'hamwooriMeal') {
    await custMailLog();

    let Map = {} as any;  
      mailLog.value.forEach((item: { hc_number: string | number; countMailLog: any; }) => {
        Map[item.hc_number] = item.countMailLog;
      });

      custList.value.forEach(item => {
        if(Map[item.hc_number] !== undefined) {
          item.countMailLog = Map[item.hc_number];
        }else {
          item.countMailLog = 0;
        }
      })


    await fetchImage();
  }else if(newValue && newValue === 'hamwooriMeal'){
    if(mealMailList.value.length === 0){
      await selectMealMail();
    }
    mealMailList.value.splice(0, 602); 
    //메일보내는 도중에 끊었을 경우, 마지막 보낸메일부터다시보냄.
    alert(`자동으로 메일보낼 기관을 선택하였습니다. \n 메일보내기 화면으로 이동합니다.`);
    mailDialogOpen();
  }
});

const fetchImage = async () => {
  imageUrl.value = await getPromotionMail();
};

const getPromotionMail = async () => {
  loading.value = true;
  try {
    const response = await axios.get(`/api/getPromotionMail/${imgName.value}`, { responseType: 'blob' });
    if(response.data){
      //이미지타입 추출
      imageType.value = response.data.type.split('/')[1];
      if(imageType.value === 'jpeg'){
        imageType.value = 'jpg';
      }
      const imageBlob = response.data;
      const imageObjectURL = URL.createObjectURL(imageBlob);
      return imageObjectURL; // 가져온 이미지 URL을 반환
    }
  } catch (error) {
    const imageObjectURL = 'nothing'
    return imageObjectURL;
  } finally {
    loading.value = false;
  }
};
const mailLog = ref([] as any)
const custMailLog = async () => {
  try {
    const response = await axios.get(`/api/custMailLog/${imgName.value}`);
    const responseData = response.data;
    if (responseData) {
      mailLog.value = responseData;
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};
const mealMailList = ref([] as any)
const selectMealMail = async () => {
  try {
    const response = await axios.get(`/api/selectMealMail/`);
    const responseData = response.data;
    if (responseData) {
      mealMailList.value = responseData;
      sendMailArr.value = mealMailList.value;
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

//스케쥴러///////////////////////////////////////////////////////////////////
const schedulerStatus = ref(false);
const sendScheduler = async () => {
  if (subject.value === '') {
    alert('메일 제목을 입력해주세요.');
    return;
  }

  const isConfirmed = window.confirm('스케줄러를 진행하시겠습니까?');
  if (isConfirmed) {
    try {
      schedulerStatus.value = true;
      const subjectEncode = encodeURIComponent(subject.value);
      const emailArray = sendMailArr.value.map(item => ({
        email: item.hc_email,
        number: item.hc_number
      }));
      // 총 이메일 수와 배치 크기 정의
      alert(`${Math.floor(sendMailArr.value.length/30) + 1} 시간 미만 소요 예정`)
      const response = await axios.post(`/api/sendScheduler/${subjectEncode}/${imgName.value}/${imageType.value}/${userId}`, { form: emailArray });
      const result = response.data;
      if (result.success) {
        alert(`${result.message}`)
      } else {
        console.error('메일 전송에 실패하였습니다.', result);
      }
    } catch (error) {
      alert('기관으로의 메일 전송이 실패하였습니다. 관리자에게 문의 바랍니다.');
      console.error('에러 메시지:', error);
    } finally {
      schedulerStatus.value = false;
    }
  }
};

const stopScheduler = async () => {
  const isConfirmed = window.confirm(`메일 스케줄러를 중지하시겠습니까? `);
  if (isConfirmed) {
    try {
      const response = await axios.post(`/api/stopScheduler`);
      const result = response.data;
      if (result) {
        schedulerStatus.value = false
        alert(`${result.message}`);
      }
    } catch (error) {
      alert(`실패하였습니다. 관리자에게 문의 바랍니다.`);
      console.error('에러 메시지:', error);
    }
  }
}
//스케쥴러///////////////////////////////////////////////////////////////////


onMounted(async () => {
  await getSido();
  imgName.value = imgNameValue.value[0].value;
  custMailLog();
})

</script>

<style scoped>
.spinner {
  border: 30px solid rgba(0, 0, 0, 0.1);
  border-left: 30px solid #8b2197;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 1s linear infinite;
  margin: 60px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>