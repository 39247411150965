<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" disable-resize-watcher>
      <v-list style="margin: 0; padding: 0;">
        <v-list-item
          v-if="role >= 7"
          :style="listItemStyle"
          @click="navigateTo('MngIndexPage', '관리자페이지')"
          :class="{ 'active-link': currentPageTitle === '관리자페이지' }"
          class="fontNotoSans700"
          style="border-bottom: 1px solid #c1c1c1;"
        >
        <img class="mainLayoutHeaderLogo mt-2"  @click="indexPage" src="@/assets/pictures/함우리로고.png"
          style="height:48px; cursor: pointer;" />
        </v-list-item>
        <v-list-item
          v-if="role >= 7"
          @click="navigateTo('MngCustPage', '기관조회')"
          :class="{ 'active-link': currentPageTitle === '기관조회' }"
          class="fontNotoSans700"
        >
        기관조회
        </v-list-item>
        <v-list-item
        v-if="role >= 8"
        @click="navigateTo('MngMemInfo', '매니저관리')"
        :class="{ 'active-link': currentPageTitle === '매니저관리' }"
        class="fontNotoSans700"
        >
        매니저관리
        </v-list-item>
        <v-list-item
          v-if="role >= 8"
          @click="navigateTo('MngDetail', '상세진단현황')"
          :class="{ 'active-link': currentPageTitle === '상세진단현황' }"
          class="fontNotoSans700"
          >
          상세진단현황
        </v-list-item>
        <v-list-item
        v-if="role === 7"
        @click="navigateTo('MngDetail', '상세진단목록')"
        :class="{ 'active-link': currentPageTitle === '상세진단목록' }"
        class="fontNotoSans700"
        >
        상세진단목록
      </v-list-item>
        <v-list-item
          v-if="role >= 8"
          @click="navigateTo('MngInquiry', '상담문의관리')"
          :class="{ 'active-link': currentPageTitle === '상담문의관리' }"
          class="fontNotoSans700"
          >
          상담문의관리
        </v-list-item>
        <v-list-item
          v-if="role >= 7"
          @click="navigateTo('MngBoardPage', '게시판')"
          :class="{ 'active-link': currentPageTitle === '게시판' }"
          class="fontNotoSans700"
          >
          게시판
        </v-list-item>
        
        <v-list-group>
          <template v-slot:activator="{ props }">
            <v-list-item
            v-bind="props"
            v-if="role >= 8"
            class="fontNotoSans700 "
            >
              기타설정
            </v-list-item>
          </template>

          <v-list-item
            v-for="cog in cogSec"
            :key="cog.title"
            class="fontNotoSans500 SsListItem"
            @click="navigateTo(cog.link, cog.title)"
            :class="{ 'active-link': currentPageTitle === cog.title }"
            >
            {{ cog.title }}
          </v-list-item>
        </v-list-group>

            <v-list-item
              v-if="role >= 7"
              @click="navigateTo('MngSsPage', '고객관리목록')"
              :class="{ 'active-link': currentPageTitle === '고객관리목록' }"
              class="fontNotoSans700"
              >
              고객관리목록
            </v-list-item>

            <v-list-item
                v-if="role >= 3 && store.state.MngIntoSs && store.state.MngIntoSs.length !== 0"
                :style="sisulItemStyle"
                @click="navigateMain()"
                class="fontNotoSans700"
                :class="{ 'active-link': currentPageTitle ===  store.state.MngIntoSs.sisul_name}"
                :prepend-icon="mdiHomeImportOutline"

              >
                {{ store.state.MngIntoSs.sisul_name }}
              </v-list-item>
            <v-list-group
              v-if="role >= 3 && store.state.MngIntoSs && store.state.MngIntoSs.length !== 0"
            >
              <template v-slot:activator="{ props }">
                <v-list-item
                  v-bind="props"
                  
                  class="fontNotoSans700 "
                >
                  기관관리
                </v-list-item>
              </template>
                <v-list-item
                  v-for="comp in compSec"
                  :key="comp.title"
                  class="fontNotoSans500 SsListItem"
                  @click="SsNavigateTo('/SsCompPage', comp.link, comp.title)"
                  :class="{ 'active-link': currentPageTitle === comp.title }"
                >
                {{ comp.title }}
                </v-list-item>
            </v-list-group>
  
            <v-list-group
              v-if="role >= 3 && store.state.MngIntoSs && store.state.MngIntoSs.length !== 0"
            >
              <template v-slot:activator="{ props }">
                <v-list-item
                  v-bind="props"
                  class="fontNotoSans700 "
                >
                  사원관리
                </v-list-item>
              </template>
              <v-list-item
                  v-for="emp in empSec"
                  :key="emp.title"
                  class="fontNotoSans500 SsListItem"
                  @click="SsNavigateTo('/SsEmpPage', emp.link, emp.title)"
                  :class="{ 'active-link': currentPageTitle === emp.title }"
                >
                {{ emp.title }}
                </v-list-item>
            
            </v-list-group>
  
            <v-list-group
              v-if="role >= 3 && store.state.MngIntoSs && store.state.MngIntoSs.length !== 0"
            >
              <template v-slot:activator="{ props }">
                <v-list-item
                  v-bind="props"
                  v-if="role >= 3 && store.state.MngIntoSs && store.state.MngIntoSs.length !== 0"
                  class="fontNotoSans700 "
                >
                  재무회계
                </v-list-item>
              </template>
          
  
            </v-list-group>
  
            <v-list-group
              v-if="role >= 3 && store.state.MngIntoSs && store.state.MngIntoSs.length !== 0"
            >
              <template v-slot:activator="{ props }">
                  <v-list-item
                    v-bind="props"
                    v-if="role >= 3 && store.state.MngIntoSs && store.state.MngIntoSs.length !== 0"
                    class="fontNotoSans700 "
                  >
                  위탁급식
                </v-list-item>
              </template>
            </v-list-group>

          
        </v-list>
      </v-navigation-drawer>
      
      <v-app-bar>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-app-bar-title>{{ currentPageTitle }}</v-app-bar-title>
      <v-spacer></v-spacer>
      <span class="mngNameRole">{{ name }}({{ roleName }})</span>
    </v-app-bar>

    <v-main>
      <v-sheet class="mgtSheetBody">

        <slot />
        </v-sheet>
    </v-main>
    <v-footer color="#191919" class="pa-0 footer">
      <v-container>
        <v-row>
          <v-col cols="4" style="align-items: center; display: flex; justify-content: center; ">
            <img class="mainLayoutHeaderLogo" @click="indexPage" src="@/assets/pictures/함우리로고.png"
              style="width:100px; height: auto; cursor: pointer;" />
          </v-col>
          <v-col cols="8" class="white--text">
                <div class="grey--text text--lighten-1" style="font-size: 12px;">
                  주식회사 함우리 | 사업자등록번호 : 185-81-02287 | 주소 : 서울시 송파구 법원로90, 전북특별자치도 전주시 덕진구 기지로77
                </div>
                <div class="grey--text text--lighten-1" style="font-size: 12px;">
                  이메일 : hamwoori25@naver.com | 전화번호 : 1833-7489
                </div>
                <div class="grey--text text--lighten-2" style="font-size: 10px;">
                  Copyright © 2024 Hamwoori. All Rights Reserved.
                  <span class="grey--text text--lighten-2">Design by Hamwoori</span>
                </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<style scoped>
.active-link {
  background-color: #ddd; /* 원하는 활성화된 링크의 배경색을 여기에 설정하세요 */
}

.v-list-item--active{
  background-color: #545454;
  color: #fff;
}
</style>

<script setup>
import store from '@/store';
import { ref, onMounted, computed, watch } from 'vue'
import router from '@/router';
import {
  mdiHomeImportOutline,

} from '@mdi/js'
const roleName = store.state.roleName;
const name = store.state.name;
const role = store.getters.role;
const drawer = ref(true);

const currentPageTitle = ref(store.state.MngTitle || '관리자페이지');
// const currentPageTitle = ref(localStorage.getItem('currentPageTitle') || '관리자페이지');
const listItemStyle = {
  height: '65px',
};

const navigateTo = (link, title) => {
  router.push(link);
  currentPageTitle.value = title;
  store.commit('setMngTitle', {title,link});
  drawer.value = true; 
};

const cogSec = [
  { title: '휴일설정', link: 'MngEtcHoliday'},
  { title: '이메일설정', link: 'MngEtcMail'}
];

const changeMngTitle = computed(() => {
  const MngTitleComputed = store.state.MngTitle
  return MngTitleComputed;
});

watch(changeMngTitle, async (newValue) => {
  if (newValue) {
    currentPageTitle.value = newValue.title;
  }
});


///고객관리코드////////////////////////////////////////////////////////////////////////////////////////
const SsNavigateTo = (path, link, title) => {
  router.push(path);
  currentPageTitle.value = title;
  store.commit('setMngTitle' ,{title, link});
  drawer.value = true; // Optional: Close the drawer after navigation
};

const navigateMain = () => {
  router.push('/SsIndexPage');
  store.commit('setMngTitle', {title: `${store.state.MngIntoSs.sisul_name}`, link: ''});
  currentPageTitle.value = `${store.state.MngIntoSs.sisul_name}`;
  drawer.value = true; 
}

const compSec = [
  { title: '서비스관리', link: 'SsService'},
  { title: '담당자설정', link: 'SsDamdang'},
  { title: '설정', link: 'SsCog'},
];
const empSec = [
  { title: '사원정보', link: 'SsEmpList'},
  { title: '임금기초', link: 'SsAttendance'},
  { title: '임금대장', link: 'SsWage'},
  { title: '휴가관리', link: 'SsDayOff'},
  { title: '퇴직급여', link: 'SsRetire'},
  { title: '계약서', link: 'SsContract'},
];

const changeMngIntoSs = computed(() => {
  const MngTitleComputed = store.state.MngIntoSs;
  return MngTitleComputed;
});

watch(changeMngIntoSs, async (newValue) => {
  if (newValue) {
    navigateMain();
    drawer.value = true;
  }
});
onMounted(() => {
  const storedTitle = store.state.MngTitle.title;
  // const storedTitle = localStorage.getItem('currentPageTitle');
  if (storedTitle) {
    currentPageTitle.value = storedTitle;
  }

});


const sisulItemStyle = {
  letterSpacing: '-0.5px',
  fontSize: '16px',
  height: '44px',
  backgroundColor: 'black',
  color: 'white'
};

</script>
